import { gql } from '@apollo/client';
import MoneyFields from '../fragments/money/money-fields';
import {
  MasterEntityBasicField,
  CompanyDebtsSummary,
  RiskBlacklistFields,
} from '../customers/fragment';

export const InvoiceCommercialManagerFields = gql`
  fragment InvoiceCommercialManagerFields on InvoiceCommercialManagerType {
    id
    invoice {
      id
      folio
      preoffer {
        id
        default_rate
        monthly_rate
        retention_rate
      }
    }
  }
`;

export const GET_INVOICE_COMMERCIAL_MANAGERS = gql`
  query GetInvoiceCommercialManagers(
    $first: Int
    $offset: Int
    $orderBy: String
  ) {
    getInvoiceCommercialManagers(
      first: $first
      offset: $offset
      orderBy: $orderBy
    ) {
      totalCount
      edges {
        node {
          ...InvoiceCommercialManagerFields
        }
      }
    }
  }
  ${InvoiceCommercialManagerFields}
`;

export const FACTORING_SIMULATIONS = gql`
  query factoringSimulations(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    factoringSimulations(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          id
          globalAppId
          factoringSimulationDetail {
            id
            monthlyRate
            invoice {
              id
              amountWithIva {
                ...MoneyFields
              }
              company {
                id
                masterEntity {
                  ...MasterEntityBasicField
                  riskBlacklist {
                    ...RiskBlacklistFields
                  }
                  ...CompanyDebtsSummary
                }
              }
            }
          }
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${MoneyFields}
  ${MasterEntityBasicField}
  ${RiskBlacklistFields}
`;
