import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import LoadingTypography from '../../components/typography/LoadingTypography';
import { formatMoney } from '../../helpers';
import { MasterEntityType, MoneyType } from '../../propTypes';
import { debtorTypeToColor, debtorTypeToSpanish } from '../collection/helpers/debtors';
import { StatisticItemStack } from '../../components/stacks';
import { StatisticCard } from '../../components/cards';

const DebtorSummaryStatisticsGrid = ({
  debtorType,
  averagePaymentTerm,
  currentDebtAmount,
  loading,
  lateCollectedInvoices,
  badDebtAmount,
  collectedInvoices,
}) => {
  const paidAfterDuePercentage = parseInt(
    (lateCollectedInvoices / collectedInvoices) * 100,
    10,
  ) || 0;

  return (
    <Grid container spacing={3} paddingX={3} sx={{ height: '100%' }}>
      <Grid item xs={12} sm={4}>
        <StatisticCard>
          <StatisticItemStack
            title="Tipo de cliente"
            value={debtorTypeToSpanish(debtorType)}
            valueProps={{ color: debtorTypeToColor(debtorType) }}
          />
          <StatisticItemStack
            title="Plazo promedio de pago"
            value={`${averagePaymentTerm || '-'} días`}
            loading={loading}
          />
        </StatisticCard>
      </Grid>
      <Grid item xs={12} sm={4}>
        <StatisticCard>
          <StatisticItemStack
            title="Deuda vigente"
            value={`${formatMoney(currentDebtAmount, true)}`}
            loading={loading}
            valueProps={{ color: 'primary.main' }}
          />
          <StatisticItemStack
            title="Deuda morosa"
            value={`${formatMoney(badDebtAmount, true)}`}
            loading={loading}
            valueProps={{ color: 'primary.main' }}
          />
        </StatisticCard>
      </Grid>
      <Grid item xs={12} sm={4}>
        <StatisticCard>
          <Box>
            <Typography fontWeight="bold" variant="body1" align="left" mt={0.5}>
              % de facturas pagadas después
            </Typography>
            <Typography fontWeight="bold" variant="body1" align="left">
              del plazo de pago acordado
            </Typography>
          </Box>
          <LoadingTypography
            variant="body1"
            loading={loading}
            sx={{ color: 'primary.main' }}
          >
            {paidAfterDuePercentage}%
          </LoadingTypography>
        </StatisticCard>
      </Grid>
    </Grid>
  );
};

DebtorSummaryStatisticsGrid.propTypes = {
  debtorType: PropTypes.oneOfType([MasterEntityType, PropTypes.string]),
  averagePaymentTerm: PropTypes.number,
  currentDebtAmount: MoneyType,
  loading: PropTypes.bool,
  lateCollectedInvoices: PropTypes.number,
  badDebtAmount: MoneyType,
  collectedInvoices: PropTypes.number,
};

DebtorSummaryStatisticsGrid.defaultProps = {
  debtorType: null,
  averagePaymentTerm: null,
  currentDebtAmount: null,
  loading: false,
  lateCollectedInvoices: null,
  badDebtAmount: null,
  collectedInvoices: null,
};

export default DebtorSummaryStatisticsGrid;
