import { gql } from '@apollo/client';

const REVOKE_REFRESH_TOKEN = gql`
  mutation RevokeToken($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      revoked
    }
  }
`;

export default REVOKE_REFRESH_TOKEN;
