import { gql } from '@apollo/client';
import { InvoiceIdFields, PreofferFields } from '../invoices/fragments';
import { MasterEntityBasicField } from '../customers/fragment';

export const CREATE_COMMERCIAL_ACTION = gql`
  mutation createCommercialAction(
    $companyId: Int!
    $actionType: String!
    $parameters: [GenericParameterType!]!
  ) {
    createCommercialAction(
      companyId: $companyId
      actionType: $actionType
      parameters: $parameters
    ) {
      success
      message
    }
  }
`;

export const UPLOAD_COMMERCIAL_RECOMMENDATION_FILE = gql`
  mutation uploadCommercialRecommendationFile($file: Upload!) {
    uploadCommercialRecommendationFile(file: $file) {
      success
    }
  }
`;

export const SEND_ASSINGMENT_CESSION_TYPE_CHANGE_MAIL = gql`
mutation sendAssignmentCessionTypeChangeMail(
  $invoiceIds: [String]!
  $newCessionType: String!
  ) {
    sendAssignmentCessionTypeChangeMail(
    invoiceIds: $invoiceIds
    newCessionType: $newCessionType
    ) {
      success
      message
  }
}
`;

export const CREATE_FACTORING_SIMULATION_FILE = gql`
  mutation CreateSimulationFile(
    $invoiceIds: [Int]!
    $invoiceDays: [Int]!
    $commission: Int!
    $simulationType: String!
    $checkCommission: Boolean
  ) {
    createSimulationFile(
      invoiceIds: $invoiceIds
      invoiceDays: $invoiceDays
      commission: $commission
      simulationType: $simulationType
      checkCommission: $checkCommission
    ) {
      url
    }
  }
`;

export const CREATE_INVOICE_SIMULATION = gql`
  mutation createSimulationInvoice($invoice: SimulateInvoice!) {
    createSimulationInvoice(invoice: $invoice) {
      invoice {
        ... InvoiceIdFields
        preoffer {
          ... PreofferFields
        }
        company {
          id
          masterEntity {
            ... MasterEntityBasicField
          }
          currentCommissionRules {
            id
            threshold
            variant
            value
          }
        }
        receiver {
          ... MasterEntityBasicField
        }
      }
    }
  }
  ${InvoiceIdFields}
  ${PreofferFields}
  ${MasterEntityBasicField}
`;

export const UPDATE_SIMULATION_INVOICE = gql`
  mutation update($updateInvoice: UpdateSimulationInvoiceInput!) {
    updateSimulationInvoice(updateInvoice: $updateInvoice) {
      invoice {
        id
      }
    }
  }
`;
